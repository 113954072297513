@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Hurricane&family=Lora&family=Montserrat&family=Playfair+Display&family=Raleway&family=Ubuntu&family=Urbanist&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700;800&family=Oxygen:wght@300;400;700&display=swap');

@tailwind base;

@layer base {
  button {
    @apply focus:outline-none  focus:ring-1 focus:ring-brand-hot  focus:ring-inset;
  }
  input {
    @apply focus:outline-none focus:appearance-none focus:ring-2 focus:ring-brand-hot focus:ring-inset;
  }
  textarea {
    @apply focus:outline-none focus:appearance-none focus:ring-2 focus:ring-brand-hot focus:ring-inset;
  }
}

@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  /* width: 100vw; */
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

body {
  /* font-family: 'Inter var', sans-serif; */
  /* color: white; */
  /* background: #101010; */
  /* background: #30524e; */
  user-select: none;
}

h1,
h2 {
  @apply subpixel-antialiased;
}

p,
h3,
h4,
h5,
h6,
li,
span {
  @apply font-Oxygen subpixel-antialiased;
}

a {
  @apply font-raleway subpixel-antialiased;
}

.cookie-container {
  @apply fixed bottom-0 z-50 bg-brand inset-x-0 flex flex-col xs:flex-row text-brand-back space-y-1 pb-4;
}

.cookie-content {
  @apply text-sm tracking-wide leading-5 text-left p-4 px-6 font-raleway;
}

.cookie-btn-wrapper {
  @apply flex w-auto items-stretch gap-2 mx-6 md:items-center;
}

.cookie-btn-accept,
.cookie-btn-decline {
  @apply rounded-lg flex-1 text-black bg-brand-back h-10 px-6 text-base;
}

.cookie-btn-decline {
  @apply bg-brand-hot px-7;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.bg {
  background: #101010;
}

.fullscreen.notready {
  color: #606060;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #808080;
  padding: 40px;
  pointer-events: none;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  font-size: 10px;
  line-height: 1.5em;
}

.menu.left {
  justify-content: flex-start;
}

.menu.right {
  justify-content: flex-end;
}

.menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.menu > div b {
  font-weight: 600;
  color: #b0b0b0;
}

.fullscreen.ready > div {
  cursor: pointer;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

.menu a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.menu a:hover {
  text-decoration: underline;
  color: inherit;
}

.pmndrs-menu {
  font-family: 'Inter var', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #808080;
  padding: 40px;
  pointer-events: none;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  font-size: 10px;
  line-height: 1.5em;
}

.pmndrs-menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.pmndrs-menu > div b {
  font-weight: 600;
  color: #b0b0b0;
}

.pmndrs-menu a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.pmndrs-menu a:hover {
  text-decoration: underline;
  color: inherit;
}

.content {
  width: 334px;
  height: 216px;
  background: #f0f0f0;
  border-radius: 3px;
  overflow-y: auto;
  padding: 0;
}

.wrapper {
  padding: 10px;
  width: 668px;
  height: 432px;
  transform: scale(0.5);
  transform-origin: top left;
}

/*
.laptop > h1 {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-weight: 600;
  font-size: 8em;
  letter-spacing: -0.075em;
  white-space: nowrap;
} */

.infobox {
  @apply w-[300px] text-brand-back;
}

.infobox h1 {
  @apply text-center text-xl font-bold;
}

.infobox p {
  @apply leading-tight pt-2 text-justify;
}

.scroll {
  @apply absolute top-0 left-0 overflow-y-auto w-full h-full m-0 p-0 select-none overflow-hidden;

  scroll-snap-type: y proximity;
}

.scroll > div {
  @apply snap-start;
}

.caption {
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  margin: 80px;
  color: rgb(27, 14, 14);
  font-size: 8em;
  font-weight: 100;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: auto;
}

.dot {
  pointer-events: none;
  position: sticky;
  top: 0px;
  display: inline-block;
  max-width: 600px;
  padding: 0;
  padding: 80px;
  color: #000000;
  line-height: 1.6em;
  font-size: 15px;
  letter-spacing: 1.5px;
}

.dot > h1 {
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: #000000;
  font-size: 5em;
  font-weight: 100;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;
}

@media only screen and (max-width: 1000px) {
  .caption {
    font-size: 4em;
  }
}

@media only screen and (max-width: 800px) {
  .caption {
    font-size: 3em;
  }
  .dot > h1 {
    font-size: 3em;
  }
}

@media only screen and (max-width: 700px) {
  .caption {
    font-size: 2em;
  }
  .dot > h1 {
    font-size: 3em;
  }
}

@media only screen and (max-width: 600px) {
  .caption {
    font-size: 1em;
  }
  .dot > h1 {
    font-size: 3em;
  }
}
/*
.reveal_from_left,
.reveal_from_right,
.reveal_from_top,
.reveal_from_bottom {
  @apply opacity-0;
} */

// Options
$black: #1b1e23;
$size: 200px;
$white: #f4eed7;
$feature: #4b9aaa;

.clock {
  height: $size;
  width: $size;
  border-radius: #{calc($size / 2)};
  display: flex;
  justify-content: center;
  position: relative;
  &__second,
  &__minute,
  &__hour,
  &__indicator {
    position: absolute;
    left: calc(50% - 1px);
    width: 2px;
    background: $white;
    transform-origin: bottom center;
    z-index: 2;
    border-radius: 1px;
  }
  &__second {
    height: #{calc($size / 2) - 10};
    margin-top: 10px;
    background: $feature;
    animation: time 60s infinite steps(60);
    z-index: 3;
  }
  &__minute {
    height: #{calc($size / 2) - 20};
    margin-top: 20px;
    opacity: 0.75;
    animation: time 3600s linear infinite;
  }
  &__hour {
    height: #{calc($size / 2) - 40};
    margin-top: 40px;
    animation: time 43200s linear infinite;
  }
  &__indicator {
    height: #{calc($size / 2) - 2};
    border-top: 2px solid $feature;
    background: none;
  }
  &__indicator:nth-of-type(5n) {
    opacity: 1;
    height: #{calc($size / 2) - 7};
    border-top: 7px solid $white;
  }
  &__axis {
    background: $feature;
    width: 5px;
    height: 5px;
    border-radius: 3px;
    position: absolute;
    z-index: 4;
    top: #{calc($size / 2) - 3};
  }
}

@for $i from 1 through 60 {
  .clock__indicator:nth-of-type(#{$i}) {
    transform: rotateZ(calc(6deg * #{$i}));
  }
}

// @keyframes time {
//   to {
//     transform: rotateZ(360deg);
//   }
// }

// Clock2

.clock-container.clocks {
  @apply p-0 m-0 w-full h-screen overflow-hidden;
}

.clock {
  border-radius: 50%;
  background: radial-gradient(#000, #000 0.1em, #fff 0.1em, #fff), #fff;
  display: inline-block;
  margin: 1%;
  padding-bottom: 31%;
  position: relative;
  top: 50%;
  width: 31%;
  opacity: 0;
  transform: translateY(-40%);
}

// Put in a no-js alternative so they're not invisible

.clock.show {
  opacity: 1;
  transform: translateY(-50%);
  transition: all 2.5s 0.5s cubic-bezier(0.12, 1.03, 0.34, 1);
}

.clock::after {
  background: red;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4%;
  height: 4%;
  z-index: 10;
}

.minutes-container,
.hours-container,
.seconds-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hours-container {
  animation: rotate 43200s infinite linear;
}

.linear {
  .minutes-container {
    animation: rotate 3600s infinite linear;
  }
  .seconds-container {
    animation: rotate 60s infinite linear;
  }
}

.steps {
  .minutes-container {
    animation: rotate 3600s infinite steps(60);
  }
  .seconds-container {
    animation: rotate 60s infinite steps(60);
  }
}

.local.steps {
  .minutes-container {
    animation: none;
  }
}

.bounce {
  .minutes-container {
    transition: transform 0.3s cubic-bezier(0.4, 2.08, 0.55, 0.44);
  }
  .seconds-container {
    transition: transform 0.2s cubic-bezier(0.4, 2.08, 0.55, 0.44);
  }
}

.hours {
  background: #000;
  width: 3.5%;
  height: 40%;
  position: absolute;
  left: 48.25%;
  top: 22%;
  transform-origin: 50% 71%;
}

.minutes {
  background: #000;
  width: 3.5%;
  height: 55%;
  position: absolute;
  left: 48.25%;
  top: 7%;
  transform-origin: 50% 78.5%;
}

.seconds {
  background: red;
  width: 1.5%;
  height: 42%;
  position: absolute;
  left: 49.25%;
  top: 20%;
  transform-origin: 50% 71%;
  z-index: 8;
}

.label {
  background: #fff;
  border-radius: 0.25em;
  color: #000;
  font-family: MyriadPro-Regular, 'Myriad Pro Regular', MyriadPro, 'Myriad Pro',
    Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5em 0.75em 0.25em;
  position: absolute;
  top: -4em;
  left: 50%;
  transform: translate(-50%, 0);
}

@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}

// clock2

.clock.station {
  background: #fff url(/station_clock.svg) no-repeat center;
  background-size: 95%;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2) inset;
}

.clock.station .seconds::before {
  background: red;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: -9%;
  left: -200%;
  height: 18%;
  width: 500%;
}

.clock.ios7 {
  background: #fff url('/ios_clock.svg') no-repeat center;
  background-size: 88%;
}

.clock.ios7:before {
  background: black;
  border-radius: 50%;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6%;
  height: 6%;
  z-index: 0;
}

.clock.ios7:after {
  width: 2%;
  height: 2%;
}

.clock.ios7 .seconds {
  border-radius: 200%/10%;
  height: 30%;
  left: 49.5%;
  top: 20%;
  width: 1%;
  transform-origin: 50% 100%;
}

.clock.ios7 .minutes {
  border-radius: 150%/10%;
  width: 2%;
  height: 35%;
  left: 49%;
  top: 15%;
  transform-origin: 50% 100%;
}

.clock.ios7 .hours {
  border-radius: 85%/10%;
  width: 2%;
  height: 20%;
  left: 49%;
  top: 30%;
  transform-origin: 50% 100%;
}

.clock.simple {
  background: #fff url('/ios_clock.svg') no-repeat center;
  background-size: 88%;
}

.clock.simple:after {
  background-color: #000;
  width: 5%;
  height: 5%;
}

.clock.simple .seconds {
  background-color: #000;
  height: 45%;
  left: 49.5%;
  top: 14%;
  width: 1%;
  transform-origin: 50% 80%;
}

.clock.simple .minutes {
  width: 2%;
  height: 40%;
  left: 49%;
  top: 10%;
  transform-origin: 50% 100%;
}

.clock.simple .hours {
  width: 2.5%;
  height: 20%;
  left: 48.75%;
  top: 30%;
  transform-origin: 50% 100%;
}

.hours.angled {
  transform: rotateZ(-40deg);
}

.minutes.angled {
  transform: rotateZ(40deg);
}

/* From cssbuttons.io by @westitan */
.buttonflo {
  position: relative;
  padding: 19px 36px;
  display: block;
  text-decoration: none;
  //text-transform: uppercase;
  overflow: hidden;
  border-radius: 3px;
  border: none;
}

.buttonflo a {
  position: relative;
  color: #fff;
  //font-family: Arial;
  letter-spacing: 5px;
  z-index: 1;
}

.buttonflo .liquid {
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
  height: 200px;
  background: #0f3330;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

.buttonflo .liquid::after,
.buttonflo .liquid::before {
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
  background: #fff;
}

.buttonflo .liquid::before {
  border-radius: 45%;
  background: rgba(20, 20, 20, 1);
  animation: animate 5s linear infinite;
}
.buttonflo1 .liquid1::before {
  border-radius: 45%;
  background: rgba(20, 20, 20, 1);
  animation: animate 9s linear infinite;
}

.buttonflo .liquid::after {
  border-radius: 40%;
  background: rgba(20, 20, 20, 0.5);
  animation: animate 13s linear infinite;
}
.buttonflo1 .liquid1::after {
  animation: animate 8s reverse infinite;
}

.buttonflo:hover .liquid {
  top: -120px;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}
